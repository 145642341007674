.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $gray-500;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}


.line-separator {
  border-left: 1px solid $gray-500;
  height: 32px;
}
.line-separator-text {
  text-align: center;
}
@media (max-width: 991px) {
.line-separator {
  border-top: 1px solid $gray-500; 
  transform: rotate(90deg); 
  }
}
