.user-list {
  border-radius: 4px;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.search-input {
  &::placeholder {
    font-weight: normal;
    opacity: 0.5;
    color: #000;
    padding-left: 1.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0.5rem center;
  }
}
